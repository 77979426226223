#caixa.cadastro {
  width: 100%;

  h3 {
    display: none;
  }

  .permissoes {
    .descricao {
      padding-left: 10px;
    }

    .switch {
      margin-top: 12px;
      margin-left: 5px;
    }
  }

  .btn_salvar {
    margin-top: 20px;
  }
}

#caixa.view {
  width: 100%;

  .titulo {
    span {
      margin-left: 15px;
      font-size: .7em;
      color: #378b2c;
      vertical-align: 3px;
    }
  }

  .saldo {
    span {
      color: #378b2c
    }
  }
}