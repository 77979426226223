.btn_search{
  width: 40px;
  max-width: 40px !important;
  min-width: 40px !important;
  height: 40px;
  margin-top: 8px !important;

  span{
    svg{
      margin-left: 10px !important;
    }
  }
}

.btn_search.MuiButton-root.Mui-disabled {
  color: #fff !important;
  background-color: #3f51b5 !important;
}