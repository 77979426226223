.topo{
    width: 100%;
    margin: auto;
    height: 95px;
    background-color: #2c4e82;
    padding: 15px 45px;

    img{
        height: 65px;
    }
}

.formLogin{
    width: 100%;
    height: 300px;
    margin-top: 60px;

    h1{
        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
        margin-top: 0
    }
}

.input{
    width: 100%;
    background: #FFF;
    border-radius: 5px;
}

.mg_top_10{
    margin-top: 10px !important
}

.mg_top_20{
    margin-top: 20px !important
}

.mg_top_40{
    margin-top:40px
}

.btn_entrar{
    background-color: #4174af !important;
    color: #FFF !important;
    width: 100%;
}

.btn_entrar:hover{
    background-color: #2c4e82 !important;
}

.btn_esqueceu{
   width: 100%;
}

.marca{
    padding: 0;
    margin: 0;
    font-size: 12px;
    text-align: right;
    margin-top: -10px
}

.w_100{
    width: 100% !important
}

.loadingLogin{
  div{
    margin-left: calc(50% - 20px);
    margin-top: 10px;
  }
}