.menu{
    color: #FFF;
    font-size: 40px !important;
    margin-top: 5px;
    margin-left: -7px;
    cursor: pointer;
}

.icon{
    float: left;
    color: #FFF;
    font-size: 40px !important;
}


.iconMenu{
  color: #FFF;
  font-size: 30px !important;
  margin-top: 5px;
  vertical-align: baseline !important;
}

.logout{
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
}

.notification{
  position: absolute;
    top: 0;
    right: 55px;
    cursor: pointer;

    .qtdNotif10{
      width: 15px;
      height: 15px;
      background-color:red;
      border-radius: 50%;
      position: absolute;
      top: 30px;
      right: 5px;

      span{
        color: #FFF;
        font-size:10px;
        text-align: center;
        position: absolute;
        margin-left: 2px;
        top: -1px;
      }
    }

    .qtdNotif9{
      width: 15px;
      height: 15px;
      background-color:red;
      border-radius: 50%;
      position: absolute;
      top: 30px;
      right: 5px;

      span{
        color: #FFF;
        font-size:10px;
        text-align: center;
        position: absolute;
        margin-left: 5px;
        top: -1px;
      }
    }
}

.menu a{
    margin-top: 5px;
}

#nav{
    svg{
        margin: 10px 10px;
    }
    span{
        display: block;
        text-decoration: none;
        color: #FFF !important;
        padding: 10px;
        font-weight: 300;
        font-size: 14px;
        margin-top: 5px;
    }
}

.itemNav{
  background: #2c4e82;
  height: 50px;
      overflow: hidden;
}

.itemNavSub{
  background: #2c4e82;
  height: 50px;
  overflow: hidden;
}

.itemNavSub:last-child{
  border-bottom: 4px solid #4682B4;
}

.backSubMenu .itemNav{
  background: transparent;
  height: 50px;
  overflow: hidden;
}

.backSubSubMenu .itemNav{
  background: #2c4e82;
  height: 50px;
  overflow: hidden;
}

.iconStar{
    color: rgba($color: #000000, $alpha: 0.2);
    z-index: 10;
}

.iconStar:hover{
    color: yellow;
}

.hidden{
    display: none;
}

.backSubMenu{
  background-color: #4682B4 !important;
  border: 1px solid #000;
  width: 100%;
}

.backSubMenu .itemNav:hover{
  background-color: #2c4e82 !important;
}

.backSubSubMenu .itemNav:hover{
  background-color: #4682B4 !important;
}

.backSubSubMenu .itemNavSub:hover{
  background-color: #4682B4 !important;
}

.itemSubMenu{
  margin-left: 20px;
}

.inputMenu{
  width: 180px;
  position: absolute;
  top: 5px;
  left: 70px;
  height: 50px;
}

fieldset{
  // border-color: #fff !important;
}

.inputM{
  label{
    color: #FFF;
  }
  input{
    color: #FFF;
  }
  .MuiFormLabel-root.Mui-focused{
    color: #FFF;
  }
}

.btn-trocar{
  position: absolute !important;
  top: 6px;
  right: 105px;
  height: 35px;

  .MuiButton-label{
    .MuiButton-startIcon.MuiButton-iconSizeSmall{
      margin: 0px !important;
  
      svg{
        font-size: 24px !important;
      }
    }
  }
}



.dadosUnidades{
  position: absolute;
  top: 5px;
  right: 255px;

  label{
    span{
      font-size: 14px;
      padding: 1px 3px;
    }
    svg{
      font-size:20px
    }
  }

  .subTitulo{
    font-size:12px;
    color:#808080;
    position: relative;
    margin: 0;
  }

  .subSubTitulo{
    font-size:12px;
    color:#808080;
    position: relative;
    left: 15px;
    margin: 0;
  }
}

.dadosUsuario{
  position: relative;
  bottom: 5px;
  right: 0; 
  margin-top: 10px;
  cursor: pointer;
  float: right;

  p{
    font-size: 12px;
    color: #fff;
    margin: 0;
    text-align: right;
  }
}

.header {
  .caixa {
    position: absolute;
    top: 6px;
   right: 645px;

    p{
      font-size: 12px;
      color: rgb(65, 223, 45);
      margin: 0;
      text-align: right;
      font-weight: bold;
    }
  }
}

.boxHomologacao{
  background-color: red;
  position: fixed;
  left: 10px;
  bottom: 4px;
  border-radius: 10px;
  height: 20px;
  padding: 0px 10px;

  p{
    margin: 0;
    font-size: 12px;
    color: #FFF;
  }
}

.boxHomologacaoRodape{
  background-color: red;
  position: relative;
  right: 0;
  bottom: 5px;
  border-radius: 10px;
  height: 40px;
  padding: 0px 10px;
  width: 70%;
  margin-top: 10px;
  text-align: center;
  float: left;

  p{
    margin: 0;
    font-size: 24px;
    color: #FFF;
  }
}

.boxHomologacaoFundo{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(139,0,0, 0.5);
    width: 300px;
    height: 30px;
    transform: rotate(315deg);
    text-align: center;
    margin-top: 100px;
    margin-left: -36px;

  p{
    margin: 0;
    font-size: 12px;
    color: #FFF;
    margin-top: 5px;
  }
}

.boxUnidadesMenuOpen{
    width: 400px;
    height: 220px;
    background-color: #FFF;
    position: absolute;
    left: -325px;
    top: 2px;
    padding: 10px 10px;
    border: 1px solid #000;
    overflow: auto;
    z-index: 200;
}

.boxUnidadesMenuOpen p{
    color: #000;
    font-size: 10px
}

.boxUnidadesMenuOpen .angle-up{
   position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 600;
    border: 1px solid #000;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px !important;
    padding: 2px 6px;
    cursor: pointer;
    z-index: 10;

    span{
      span{
        margin-left:0px !important;
        margin-right: 0px !important;
        svg{
          font-size:25px !important;
        }
      }
    }
}

.boxUnidadesMenuClosed{
    width: 400px;
    height: 35px;
    background-color: #FFF;
    position: absolute;
    left: -325px;
    top: 3px;
    padding: 10px 10px;
}

.boxUnidadesMenuClosed p{
    color: #000;
    font-size: 10px
}

.boxUnidadesMenuClosed .angle-down{
    position: absolute;
    top: 6px;
    right: 6px;
    font-weight: 600;
    border: 1px solid #000;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px !important;
    padding: 4px 6px;
    cursor: pointer;
    z-index: 200;

    span{
      span{
        margin-left:0px !important;
        margin-right: 0px !important;
        svg{
          font-size:25px !important;
        }
      }
    }
}

.hidden{
    display: none
}

.block{
    display: block;
}

.franquias{
  position: relative;
  padding: 3px;
}

.unidades{
  position: relative;
  left: 30px;
}

.mg-top--10{
  margin-top: -10px;
}

hr{
  border: 1px solid #C0C0C0;
}
.acesso_atual{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 14px !important;
  margin: -2px;
}

.openNotification{
  width: 300px;
  max-height: 300px;
  background-color: #FFF;
  z-index: 100;
  position: absolute;
  right: 0;
  border: 1px solid;
  top: 50px;
  overflow: auto;
  padding: 10px;

  .itemNotificacao{
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    p{
      font-size: 12px;
      white-space: pre-wrap;
      margin: 10px;
      color: #FFF
    }
  }
}

.unidadeInativa{
  font-size: 14px;
  padding: 1px 3px;
}