.tituloCampo{
  margin: 0px !important;
  text-align: center;
}

.separacaoParcelas{
  font-size: 25px !important;
  margin: 0px 5px 0px 5px !important;
}

.inputParcela{
  width: 50px !important;
  margin: 5px 0px !important;
}

.inputValor{
  width: 140px !important;
  margin: 5px 0px !important;
}

.inputVencimento{
  width: 200px !important;
  margin: 5px 0px !important;
}

.inputFormPagamento{
  width: 250px !important;
  margin: 5px 0px !important;
}

.inputDocumento{
  width: 350px !important;
  margin: 5px 0px !important;
}

.inputConta{
  width: 250px !important;
  margin: 5px 0px !important;
}

.scroll{
  overflow: auto !important;
  width: 100%;
}