.btn_ativo{
  background-color:#3f51b5 !important;
  color: #fff !important;
  width: 80px !important;
}

.btn_inativo{
  background-color: #f50057 !important;
  color: #fff !important;
  width: 80px !important;
}