.iconRel{
  color: #000;
  font-size:16px
}

.content-fly{
  margin-top: -15px;
  border: 1px solid #919191;
  border-radius: 10px;
  padding: 10px;
}

.cardCarteira{
  .border{
    border: 1px solid #919191;
    border-radius: 10px;
    background-color: #dadada;
    // height: 300px;
  }
}

.porcentagem{
  border: 1px solid #000;
  height: 26px;
  width: 390px;

  div{
    padding: 0 0 0 5px;
    background-color: #00BFFF;
    height: 100%;

    text-align: left;
  }
}

.relatorio{
  tr{
    height: 30px;

    th{
      background-color: #2c4e82;
      color: #FFF;
    }

    th:last-child{
      width: 400px !important;
    }

    th:first-child{
      width: 400px !important;
    }

    td:first-child{
      text-align: right;
    }

    td{
      font-size: 16px;
      font-weight: 400 !important;
    }
  }
}
#fluxo_caixa {
  table {
    width: 100%;
    border-spacing: 0;
  }
  
  thead, tbody, tr, th, td { display: block; }
  
  // thead tr {
  //   /* fallback */
  //   width: 97%;
  //   /* minus scroll bar width */
  //   width: -webkit-calc(100% - 16px);
  //   width:    -moz-calc(100% - 16px);
  //   width:         calc(100% - 16px);
  // }
  
  tr:after {  /* clearing float */
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
  
  .rolagemTabela {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rolagemTabelaParcelas{
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  tbody td, thead th {
    // width: 19%;  /* 19% is less than (100% / 5 cols) = 20% */
    float: left;
  }
  
  th, td {
    font-size: 14px !important;
  }

  td{
    font-weight:500;
    p{
      font-weight:500 !important;
      font-size: 14px !important;
    }
  }
}