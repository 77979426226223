.rbc-event{
  height: 25px !important;
  font-size: 15px !important;
  padding: 0 10px !important;
}

.MuiFormControl-root{
  width: 100% !important;
}

#simple-modal-title-event{
  margin-top: 5px !important;
}

.cardAllAlunos{
  height: 200px;
  max-height: 200px;
  border: 1px solid #C0C0C0;
  overflow: auto;
}

.cardAlunoEvent{
  padding: 5px 10px;
  border-bottom: 1px solid #C0C0C0;
  height: 40px;
  position: relative;

  p{
    margin: 0 !important;
    float: left;
    font-size: 16px !important;
  }

  .btn_editar_aluno{
    min-width: 30px !important;
    width: 30px !important;
    float: left;
    position: absolute;
    right: 15px;

    span{
      span{
        margin-left: 8px !important;
      }
    }
  }

  .btn_swap_aluno{
    min-width: 30px !important;
    width: 30px !important;
    float: left;
    position: absolute;
    right: 55px;

    span{
      span{
        margin-left: 8px !important;
      }
    }
  }
}


.modal_evento{
  p{
    margin: 0px !important;
    margin-bottom: 5px !important;
    font-size: 16px !important;
  }
}

.lista {
  width: 100%;
  margin-left: 0;
  border-collapse: collapse;

  tr:nth-child(even) {background: #FFF}
  tr:nth-child(odd) {background:#f6f6f6} 

  tr:first-child {
    border-bottom: 2px solid #ddd;
    border-top: none;
  }
  
  tr {
    border-bottom: none;
    border-top: 1px solid #ddd;
  }

  .acompanha {
    z-index: 10;
  }

  th:first-child, .lista td:first-child {
    padding-left: 15px;
  }

  th {
    background: #f6f6f6;
    white-space: nowrap;
  }

  th, td {
    text-align: left;
    padding-left: 0;
    padding-right: 15px;
    font-weight: 600;
    vertical-align: top;
  }

  .titulos{
    th {
      font-size:16px;
      text-align: center;
      font-weight: 600;
    }
  }

  .w-60{
    width: 60px;
  }

  td{
    text-align: center;
    span{
      font-size:14px;
      font-weight: 400;
    }
    p{
      margin: 3px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  .borderBottom{
    border-bottom:3px solid #000
  }
}

.sempadding{

  th:first-child, td:first-child {
    padding-left: 0;
  }

  th,td {
    white-space: nowrap;
    padding: 0 0 0 1px;
    font-size: 10px;
  }

  th {
    padding: 0;
  }
} 

.aula_disp{
  background-color: #FFFF00;
  border: 1px solid #ddca76;
  margin-top: 2px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  width: 60px;
  height: 45px;
  max-width: 60px;
  min-width: 60px;
  margin-left: calc(50% - 30px);
  
  p{
    margin-top: 12px !important;
  }
}

.aula_marcada{
  background-color: #31708F;
  border: 1px solid #000;
  margin-top: 2px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 45px;

  p{
    color: #FFF;
    margin-top: 0px !important;
  }
}

.aula_lotada{
  background-color: #9b4449;
  border: 1px solid #000;
  margin-top: 2px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 45px;

  p{
    color: #FFF;
    margin-top: 0px !important;
  }
}

.aula_aberta{
  background-color: #b5d56d;
  border: 1px solid #62a426;
  margin-top: 2px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 45px;

  p{
    margin-top: 0px !important;
    color: #000
  }
}

tr.feriado{
  background-color: red !important;

  td{
    span{
      font-size:14px;
      font-weight: 400;
    }
    p{
      color: #FFF;
      text-align: center;
    }
  }
}

.agenda th {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}

.capitalize{
  text-transform: capitalize;
}

.cardFinPendenteClosed {
  height: 85px;
  overflow: hidden;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
}

.cardFinPendenteOpen {
  overflow: hidden;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
}