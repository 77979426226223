.spanBaixaAula{
  float: left;
  margin-top: 15px;
  margin-right: 16px;
  font-weight: 600;
}

.inputProfessor{
  width: 300px;
  float: left;
}

.PrivateSwitchBase-root-1 {
  padding: 9px !important;
}

.spanTabelaSchedule{
  position: relative;
  top: 15px;
  margin-left: 10px !important;
}

.switchSchedule{
  position: relative;
  top: 10px;
}

.textalignleft{
  text-align: left !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield;
}

table .MuiIconButton-root {
  padding: 7px;
}