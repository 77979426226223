.mg_top_10{
    margin-top: 10px !important;
}
.mg_top_20{
    margin-top: 20px !important;
}
.mg_top_30{
    margin-top: 30px !important;
}

.mg_bottom_10{
    margin-bottom: 10px !important;
}

.mg_bottom_20{
    margin-bottom: 20px !important;
}

.mg_left_10{
    margin-left: 10px !important;
}