#viewAluno{
  .MuiTab-root {
      min-width: 20% !important;

      span{
        font-size:14px;
      }
  }
} 

.MuiBox-root {
  padding: 0 !important;
}

#view{
  input{
    color: #000 !important;
    background-color: #FFF !important;
  }
  label{
    color: #000 !important;
  }
}

#viewBlue{
  input{
    color: #00008B !important;
    background-color: #FFF !important;
  }
  label{
    color: #00008B !important;
  }
}

.cardEndereco{
  border: 1px solid #c0c0c0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.alertaNaoDesfeita{
  border: solid 1px #ddca76;
  background-color: #FF0;
  color: #660;
  text-shadow: 0 0 0 #660;
  font-size: 12px;
  text-align: center;
}

.margin-0{
  margin: 0px;
}

.legAulaAberta{
  width: 20px;
  height: 20px;
  border: 1px solid #62a426 !important;
  background-color: #b5d56d !important;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
}

.legAulaMarcada{
  width: 20px;
  height: 20px;
  border: 1px solid #000 !important;
  background-color: #31708f !important;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
}

.legHoraDisp{
  border: 1px solid #ddca76 !important;
  background-color: #ff0 !important;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
}

.legAulaLotada{
  border: 1px solid #000 !important;
  background-color: #9b4449 !important;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  float: left;
  margin-right: 10px;
}

.textoTabelaHistorico{
  font-size:14px !important
}

.textoTabelaCabHistorico{
  font-size:14px !important
}

.linhaTotal{
  background-color: #2c4e82 !important;

  th{
    span{
      color: #FFF;
    }
  }

  th:first-child{
    text-align: right !important;
  }
}

.desc-ausencia{
  font-size: 16px;
  margin: 0px
}

.agenda th{
  border: 1px solid #c0c0c0 !important;
}

.tabelaAgenda tbody tr td{
  border: 1px solid #c0c0c0 !important;
}

.imagemLogo{
  width: 300px;
  margin-left: calc(50% - 150px);
  margin-top: 50px;
}