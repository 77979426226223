.titulo{
    margin: 0;
}

.borderBottom{
    border-bottom: 1px solid #000;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.btnCadastrar{
    margin-top: 10px !important;
    width: 100% !important;
}


h3{
    margin-bottom: 0;
}

h4{
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardEndereco{
  background-color: rgba(0,0,0, 0.01);
  border: 1px solid #c0c0c0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 10px
}


#view{
  label{
    color: #000;
  }
  input{
    color: #000 !important;
  }
}

.text-align-right{
  text-align: right;
}