#view{
  input{
    color: #000 !important;
  }
  label{
    color: #000 !important;
  }
}

.cardApresentacao{
  width: 100%;
  height: 30px;
  padding: 0 10px;
  border-radius: 7px;
  margin-top: 7px;
}

.apresentacao{
  border: 1px solid #000;
  border-radius: 10px;
  width: 100%;
  padding: 15px
}

.perguntaTrocaQtdAlunos{
  font-size: 14px;
  margin-top: 20px;
}

.qtdMinima{
  margin-top: 10px;
  margin-right: 10px;
}

.qtdMaxima{
  margin-top: 10px;
  margin-left: 10px;
}

.padrao{
  font-size: 14px;
}

.erro{
  color: red;
  font-size: 16px;
}

.pAulaFixa{
  font-size: 16px !important;
  margin-top: 10px !important;
}

table{
  border: 2px solid #ddd;
}

td{
  padding: 5px;
}