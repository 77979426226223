#rematricula.cadastro {
  width: 100%;

  h3 {
    display: none;
  }

  .permissoes {
    .descricao {
      padding-left: 10px;
    }

    .switch {
      margin-top: 12px;
      margin-left: 5px;
    }
  }

  .btn_salvar {
    margin-top: 20px;
  }
}