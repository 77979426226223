.alunoTabela{
  margin: 0;
}

.alunoTabelaAgendas:hover{
  color: red;
}

.texto_tooltip{
  font-size: 16px;
  margin: 0px;
  margin-bottom: 5px;
}