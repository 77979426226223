.topo_cadastro{
	width: 100%;
	margin: auto;
	height: 60px;
	background-color: #2c4e82;
	padding: 10px 30px;

	span{
		color: #FFF;
		font-size: 24px;
	}
}

.card_cadastro{
	border: 1px solid #C0C0C0;
	border-radius: 15px;
	text-align: center;

	img{
		width: 60%;
		margin-left: 20%;
	}
}

.icon_back{
	color: #FFF;
	margin-right: 10px;
	font-size: 36px;
	margin-top: -5px;
	cursor: pointer;
}

.card_aviso{
	padding: 15px !important;
	text-align: justify;
	background-color: #fdf8e4;
	border-left: 5px solid #dcb476;
	
	p{
		color: #9a7b4d;
		font-size: 14px;
	}

	p:last-child{
		margin-bottom: 0;
	}
}

.card_dados{
	border: 1px solid #d0d0d0;
	border-radius: 10px;
	padding: 10px !important;
}

label{
	color: #000 !important;
}

.valor_total{
	width: 100%;
	background-color: #e5fff3;
	p{
		color: #468862;
	}
}

.marca{
	padding: 0;
	margin: 0;
	font-size: 12px;
	text-align: right;
	margin-top: -10px
}