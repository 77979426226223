.container-boletos{
  width: 100%;
  background-color: #FFF !important;
  padding: 20px
}

.container_logo_cliente{
  position: fixed;
  width: 100%;
}

.icone_nfse{
  width: 28px !important;
  margin-left: calc(50% - 14px);
}

.inputCpfCnpj{
  width: 100%;
  padding: 18.5px 14px !important;
  height: 57px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}

.clicksistemas{
  position: fixed;
  bottom: 0px;
  right: 10px;
  font-size: 12px;
}