#ranking_bff {
  table-container {
    width: 100%;
    height: 300px;
    overflow-y: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  
  }
  
  th {
    background-color: #2c4e82;
    color: #FFF;
    border: 1px solid #C0C0C0;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding: 3px;
  }
  
  td {
    background-color: #FFF;
    color: #000;
    border: 1px solid #C0C0C0;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
  }
  
  thead {
    position: sticky;
    top: 0;
    z-index: 9999;
  }
  
  input {
    font-size: 14px !important;
  }

  .cardEditor{
    border: 1px solid #000 !important;
  
    .DraftEditor-root{
      min-height: 400px;
      max-height: 400px;
      overflow: auto;
    }
  }
}
