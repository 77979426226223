html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 18px;
  background: #fff;
}

a {
  text-decoration:none !important; 
}

* {
  box-sizing: border-box
}

a {
  color:#000 ;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

:root{
  --bg-dark: #1A2F3A;
  --logo-height: 100px;
  --header-height: 50px;
  --aside-width: 175px;
  --footer-height: 50px;
  --menu-top-height: 70px;

  --shadow: 
      0 2px 23px 0 rgba(0, 0, 0, 0.1),
      0 2px 49px 0 rgba(0, 0, 0, 0.06);
}

*{
  box-sizing: border-box;
}

/* Layout em Grid */
.app-menu-open {
  margin: 0px;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 
      var(--header-height)
      1fr
      var(--footer-height);
  grid-template-areas: 
      "header header"
      "menu content"
      "menu footer";
  min-height: 100vh;
  height: 100%;
  background-color:#eee;
}

.app-menu-closed {
  margin: 0px;
  display: grid;
  grid-template-columns: 5% 95%;
  grid-template-rows: 
      var(--header-height)
      1fr
      var(--footer-height);
  grid-template-areas: 
      "header header"
      "menu content"
      "menu footer";
  min-height: 100vh;
  height: 100%;
  background-color:#eee;
}

aside.logo{
  grid-area: logo;
}

header.header{
  grid-area: header;
}

aside.menu-area{
  grid-area: menu;
}

main.content{
  grid-area: content;
}

footer.footer{
  grid-area: footer;
}

header.header{
  background-color: #2c4e82;
  padding: 0px 15px;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: var(--shadow)
}

aside.menu-area {
  background:  #2c4e82;       
}

footer.footer{
  padding: 0 25px;
  background-color:  #2c4e82;
  box-shadow: var(--shadow)
}

.menu a{
  display: block;
  text-decoration: none;
  color: #FFF !important;
  padding: 10px;
  font-weight: 300;
  font-size: 14px;
}

#nav{
  display: none;
}

.itemNav:hover{
  background-color: #4682B4;
}

main > div {
  background-color: #FFF;
  box-shadow: 0px 0px 15px #0004;
}

.h-90 {
  min-height: 95%;
}

.p-3{
  padding: 1rem!important;
} 

.mt-3{
  margin-top: 1rem!important;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #FFF !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color:#FFF !important;
  opacity: 1;
}
.MuiTableSortLabel-root:hover {
  color: #FFF !important;
}

// .MuiPaper-elevation1 {
//   border: 1px solid #000;
// }

.MuiTableCell-paddingNone {
  padding: 10px !important;
}

.MuiTableCell-root {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

.input{
  width: 100%;
  background: #FFF;
  border-radius: 5px;
}


@media (max-width: 700px) {
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 100% 0%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 0% 100%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
}

@media (min-width: 701px) {
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 0% 100%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
}

@media (min-width: 820px) {
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 28% 72%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 6% 94%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
   min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
}

@media (min-width: 1000px) {
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 6% 94%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
}

@media (min-width: 1200px) {
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 5% 95%;
    grid-template-rows: 
        var(--header-height)
        1fr
        var(--footer-height);
    grid-template-areas: 
        "header header"
        "menu content"
        "menu footer";
    min-height: 100vh;
  height: 100%;
    background-color:#eee;
  }
}


tbody .MuiTableCell-sizeSmall {
  padding: 0px 10px 0px 10px !important;
}

.imagemTable{
  width: 24px; 
  height: 24px;
  margin-left: calc(50% - 12px);
}

.card{
  margin-top: 20px;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 20px;
}

.buttonAdd{
  background-color: green !important;
  color: #FFF !important;
  width: 40px;
  height: 40px;
}

.buttonRemove{
  background-color: darkred !important;
  color: #FFF !important;
  width: 40px;
  height: 40px;
}

.alignCenter{
  text-align: center;
}
.row-grey {
  background: #f3f3f3;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.error{
  font-size: 12px !important;
  color: red;
  position: relative;
  top: -3px;

  display: none;
}

table .MuiSvgIcon-root {
//  font-size: 1.4rem !important;
}

table .MuiIconButton-root{
  padding: 2px;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 12.5px !important;
    padding-bottom: 10.5px !important;
}

td button:last-child{
  // margin-left: 10px;
}

.progress{
  height: 20px !important;
}

.t-a-r{
  text-align: right;
}
.t-a-c{
  text-align: center;
}
.t-a-l{
  text-align: left;
}

.cardRel{
  border: 1px solid;
  padding: 10px;
}

.f-s-14{
  font-size:14px
}

hr{
  margin: 0
}

.progressCircular{
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.progressCircularRelative{
  width: 40px;
  height: 40px;
  text-align: center;
  position: relative;
  top: 45%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.loadingTela{
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  left: 0;
  text-align: center
}

.progressText{
  width: 400px;
  height: 20px;
  text-align: center;
  position: absolute;
  top: 56%;
  left: 50%;
  margin-left: -200px;
  margin-top: -10px;
}

.progressTextRelative{
  width: 400px;
  height: 20px;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: 0px;
}

.makeStyles-paper-37{
  border: 2px solid #2c4e82 !important
}
.align-right{
  text-align: right;
}

.align-center{
  text-align: center;
}

.align-left{
  text-align: left;
}

.inputCenter div input{
  text-align: center !important;
}

.margin0{
  margin: 0 !important;
}

.mg-top-7{
  margin-top: 7px;
}
.tituloConfirmacao{
  margin: 0px;
  text-transform: uppercase;
  color: #a98b15;
  margin-bottom: 0px !important;
  margin-top: 0px;
}

.tituloCancelamento{
  margin: 0px;
  text-transform: uppercase;
  color: red;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 16px 6px 16px !important;
}
.color-grey{
  color: grey
}

.MuiTableRow-root {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  height: 40px;
}

.MuiButton-containedSecondary {
  color: #fff !important;
  background-color: #f50057 !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 39px;
}

// .MuiOutlinedInput-input {
//   padding: 18.5px 14px !important;
//   height: 20px !important;
// }

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 9.5px 4px !important;
}

.MuiButton-containedSizeSmall {
  padding: 4px 10px;
  font-size: 0.8125rem;
}

.MuiButton-fullWidth {
  width: 100%;
}

.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
}

.MuiButton-root {
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  min-width: 50px !important;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 4px !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.rdw-editor-toolbar{
  border-bottom: 1px solid #000 !important;
}

.cardEditor{
  border: 1px solid #000 !important;

  .DraftEditor-root{
    max-height: 400px;
    overflow: auto;
  }
}

.MuiInputBase-inputMultiline {
  height: auto !important;
  resize: none;
  padding: 0;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.positivo{
  color: green;
  font-size: 12px !important;
}

.negativo{
  color: red;
  font-size: 12px !important;
}

.avisoConciliacao{
  color: red !important;
  font-size: 14px;
  text-align: center;
}
table{
  min-width: 0 !important;
}

// rect:first-child{
//   x: 0;
//   y: 0;
//   width: 1539;
//   height: 1500;
//   stroke: none;
//   stroke-width: 0;
//   fill: rgb(255, 255, 255);
// }


html{
  height: 100% !important;
  background-color: #FFF;
}

.selecionado fieldset{
  height: 45px !important;
}

.nao_selecionado fieldset{
  height: 45px !important;
}

.nao_selecionado svg{
  margin-top: -9px !important;
}

.selecionado svg{
  margin-top: -10px !important;
}

.selecionado .MuiListItemIcon-root{
  color: rgba(0, 0, 0, 0.54);
  display: block;
  min-width: 30px !important;
  flex-shrink: 0;
  float: left;
  margin-top: -15px;
}

.selecionado .MuiListItemText-root{
  margin-top: -11px;
}

.MuiAlert-filledInfo {
  // color: #fff;
  // font-weight: 500;
  // background-color: #2196f3;
  // width: 400px;
  // height: 100px;
}

.MuiAlert-icon {
  display: flex;
  opacity: 0.9;
  padding: 8px;
  margin-right: 12px;
  margin-top: 3px;
  font-size: 25px !important;
}

.MuiAlert-message {
  padding: 8px;
  font-size: 20px;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #3f51b5 !important;
}

.MuiSwitch-switchBase {
  color: #fafafa !important;
}

.MuiSwitch-track {
  opacity: 0.38 !important;
  background-color: #000 !important;
}

tr:nth-child(even) {
  background: #DCDCDC;
}

.modalAssinarContrato{
  position: absolute;
  background-color: #FFF;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 16px 32px 24px;
  width: 90%;
}

.signatureCanvas{
  border: 1px solid #000;
  width: 100%;
  min-height: 300px;
}

@media (max-width: 700px) {
  .modalAssinarContrato{
    width: 94%;
  }
}

.btn_search_func {
  height: 40px !important;
  width: 100% !important;

  .MuiButton-endIcon {
    display: inherit;
    margin-left: 0px;
    margin-right: 0px;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 24px;
    }
  }
}